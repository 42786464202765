import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import classnames from 'classnames'
import CustomLink from '~utils/CustomLink'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'

const PageHeader = ({ bgColor, ctaHref, ctaText, extraClassname, headline, subHeadline, textColor }) => (
  <div className={classnames(styles.wrapper, extraClassname)} style={{ background: bgColor }}>
    <Container extraClassName={styles.container}>
      <h3 className={styles.headline} style={{ color: textColor }}>
        {headline}
      </h3>
      {subHeadline && !subHeadline.spans && <h4 className={styles.subheadline}>{subHeadline}</h4>}
      {/* {subHeadline.spans && <RichText render={subHeadline} serializeHyperlink={CustomLink} />} */}

      {ctaText && (
        <a className={styles.cta} href={ctaHref} target="_blank" rel="noreferrer">
          {ctaText}
        </a>
      )}
    </Container>
  </div>
)

export default PageHeader

PageHeader.propTypes = {
  bgColor: PropTypes.string.isRequired,
  ctaHref: PropTypes.string,
  ctaText: PropTypes.string,
  extraClassname: PropTypes.string,
  headline: PropTypes.string.isRequired,
  subHeadline: PropTypes.string,
  textColor: PropTypes.string.isRequired,
}

PageHeader.defaultProps = {
  ctaHref: null,
  ctaText: null,
  extraClassname: null,
  subHeadline: null,
}
