// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--3LUQe";
export var ctBlack = "style-module--ctBlack--1P7kF";
export var ctSmall = "style-module--ctSmall--2YqSe";
export var ctMedium = "style-module--ctMedium--2UVNq";
export var ctLarge = "style-module--ctLarge--V7hnG";
export var headline = "style-module--headline--38poV";
export var ctXLarge = "style-module--ctXLarge--3tAI0";
export var ctIntro = "style-module--ctIntro--2w62R";
export var ctBody = "style-module--ctBody--2Drn5";
export var ctBodyMedium = "style-module--ctBodyMedium--3A8bR";
export var ctBodyBold = "style-module--ctBodyBold--2WCzG";
export var ctLink = "style-module--ctLink--3zc9-";
export var cta = "style-module--cta--28uUH";
export var frutiLarge = "style-module--frutiLarge--1cN6s";
export var ctUi = "style-module--ctUi--2dqAW";
export var subheadline = "style-module--subheadline--poTb4";
export var ctMeta = "style-module--ctMeta--2NdaN";
export var ctMicro = "style-module--ctMicro--2EIcS";
export var ctMediumItalic = "style-module--ctMediumItalic--o-2gJ";
export var wrapper = "style-module--wrapper--34mvG";
export var container = "style-module--container--jjck2";