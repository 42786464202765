import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'

const SubscriptionBanner = () => {
  const { prismicSubscriptionBanner } = useStaticQuery(graphql`
    query {
      prismicSubscriptionBanner {
        data {
          headline {
            html
          }
          description {
            html
          }
          image {
            alt
            url
          }
          submit_text {
            html
          }
        }
      }
    }
  `)

  return (
    <div className={styles.SubscriptionBanner}>
      <div>
        <div className={styles.title}>
          {parse(prismicSubscriptionBanner.data.headline.html)}
        </div>
        <div className={styles.description}>
          {parse(prismicSubscriptionBanner.data.description.html)}
        </div>
        <Link to="/subscribe" className={styles.submit}>
          {parse(prismicSubscriptionBanner.data.submit_text.html)}
        </Link>
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={prismicSubscriptionBanner.data.image.url} alt={prismicSubscriptionBanner.data.image.alt} />
      </div>
    </div>
  )
}

// SubscriptionBanner.propTypes = {
//   data: PropTypes.shape({
//     submit_text: PropTypes.shape({
//       text: PropTypes.string,
//     }).isRequired,
//     headline: PropTypes.shape({
//       text: PropTypes.string,
//     }).isRequired,
//     description: PropTypes.shape({
//       text: PropTypes.string,
//     }).isRequired,
//     image: PropTypes.shape({
//       url: PropTypes.string,
//       alt: PropTypes.string,
//     }).isRequired,
//   }).isRequired,
// }

export default SubscriptionBanner
