// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--218vR";
export var header = "style-module--header--2rHmC";
export var ctBlack = "style-module--ctBlack--cY_uL";
export var title = "style-module--title--xs8g-";
export var ctSmall = "style-module--ctSmall--5M1Rr";
export var ctMedium = "style-module--ctMedium--1JUGe";
export var bodyHeading = "style-module--bodyHeading--2MOMh";
export var textContainer = "style-module--textContainer--FGUqc";
export var ctLarge = "style-module--ctLarge--3TleY";
export var ctXLarge = "style-module--ctXLarge--3Zl54";
export var ctIntro = "style-module--ctIntro--3hhS5";
export var ctBody = "style-module--ctBody--XPCi1";
export var bodyText = "style-module--bodyText--Rh3cb";
export var introText = "style-module--introText--3z0cL";
export var ctBodyMedium = "style-module--ctBodyMedium--1-QKr";
export var ctBodyBold = "style-module--ctBodyBold--31TMB";
export var ctLink = "style-module--ctLink--31CE2";
export var frutiLarge = "style-module--frutiLarge--1d6xS";
export var ctUi = "style-module--ctUi--1vmwJ";
export var ctMeta = "style-module--ctMeta--1shk9";
export var ctMicro = "style-module--ctMicro--1m8O5";
export var ctMediumItalic = "style-module--ctMediumItalic--1Epax";
export var container = "style-module--container--3uRy8";
export var pageHeader = "style-module--pageHeader--2ZIF0";
export var blockImg = "style-module--block-img--xXNHe";
export var imageContainer = "style-module--imageContainer--3fpF2";
export var bodyImage = "style-module--bodyImage--3Qv9Q";
export var image = "style-module--image--IzK-F";
export var headerText = "style-module--headerText--3RXyu";