import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { Layout } from '../../components/Layout'
// import { Seo } from '../../components/Seo'
import Seo from '~components/globals/Seo'
import parse from 'html-react-parser'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'
import PageHeader from '~components/globals/PageHeader'
import SubscriptionBanner from '~components/SubscriptionBanner'

export const About = ({
  data: {
    prismicAboutPage: { data },
    allPrismicPage,
  },
  location,
}) => {
  const { content, image, headline, intro } = data
  const { sharing_description, sharing_image, sharing_title } = allPrismicPage.edges[0].node.data

  return (
    <Container extraClassName={styles.container}>
      <Seo
        title={sharing_title.text}
        description={sharing_description.text}
        url={location.href}
        image={sharing_image.url}
      />
      <div className={styles.header}>
        <div className={styles.image}>
          <img src={image.url} alt={image.alt} />
        </div>
        <div className={styles.headerText}>
          <h4 className={styles.eyebrow}>
            About
          </h4>
          <h2 className={styles.title}>
            {headline.text}
          </h2>
        </div>
      </div>
      <Container extraClassName={styles.textContainer}>
        {/* <PageHeader
          bgColor="#fff"
          extraClassname={styles.pageHeader}
          textColor="#000"
          headline={headline.text}
        /> */}
        <div data-html className={styles.introText}>
          {parse(intro.html)}
        </div>
      </Container>
      <div data-html className={styles.textContainer}>
        {parse(content.html)}
      </div>
      <SubscriptionBanner />
    </Container>
  )
}


export default About

export const pageQuery = graphql`
  query {
    prismicAboutPage {
      data {
        headline {
          text
        }
        image {
          alt
          url
        }
        intro {
          html
        }
        content {
          html
        }
      }
    }
    allPrismicPage(filter: {data: {slug: {eq: "about"}}}) {
      edges {
        node {
          id
          data {
            sharing_description {
              html
              text
            }
            sharing_image {
              alt
              url
            }
            sharing_title {
              html
              text
            }
          }
        }
      }
    }
  }
`
